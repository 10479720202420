import React from "react";
import ComponentContainer from "../../../global/ComponentContainer/ComponentContainer";
import style from "./PrivatePolicy.module.scss";

const PrivatePolicy = () => {
    const config = {
        title: "개인정보취급방침",
        sectionID: "privatepolicy",
        bgImg: "SiteMapsAdministrator"
    };

    return (
        <ComponentContainer config={config}>
            <div className={style.container}>
                <div className={style.block}>
                    <h1>개인정보처리방침</h1>

                    <p>성연인더스트리(이하 '회사')는 웹사이트 (sungyun.kr) 이용 및 제반 서비스 제공시 개인정보보호법령에 따라 이용자의 개인정보 보호 및 권익을 보호하고 이용자의 고충을
                        원활하게 처리할 수 있도록 다음과 같은 처리 방침을 두고 있습니다.</p>
                </div>

                <hr/>

                <div className={style.block}>
                    <h3>[개인정보 수집에 대한 동의]</h3>
                    <p>
                        회사는 이용자가 개별 서비스 신청시 개인정보 수집 및 이용에 동의할 수 있는 절차를 마련하고 있습니다. 이용자는 동의를 거부할 수 있으며 동의를 거부하는 경우 해당 서비스
                        이용이
                        제한될 수 있습니다.
                    </p>
                </div>

                <div className={style.block}>
                    <h3>[개인정보 수집 및 이용 목적]</h3>
                    <p>회사가 이용자의 개인정보를 수집 및 이용하는 목적은 다음과 같습니다.</p>

                    <ul>
                        <li>회원 가입시 : 서비스 부정 이용 방지, 각종 고지 및 안내, 문의 내용에 대한 답변, 분쟁 조정을 위한 기록 보존</li>
                        <li>비회원 문의 접수 시 : 서비스 부정 이용 방지, 문의 내용에 대한 답변, 분쟁 조정을 위한 기록 보존</li>
                    </ul>
                </div>

                <div className={style.block}>
                    <h3>[개인정보 수집 항목 및 방법]</h3>
                    <p>
                        1) 회사는 기본적인 서비스 제공을 위한 필수 정보만을 수집하고 있으며, 선택 정보의 경우 입력하지 않더라도 서비스 이용이 제한되지 않습니다. 회사는 수집한 정보를 이용
                        목적 외의
                        목적으로 사용하지 않습니다.
                    </p>

                    <ul>
                        <li>회원 가입시 : (필수) 이름, 성별, 생년월일, 전화번호(휴대전화번호), 이메일 / (선택) 회사(단체)명, 회사(단체)전화번호</li>
                        <li>비회원 문의 접수 시 : (필수) 이름, 성별, 생년월일, 전화번호(휴대전화번호), 이메일 / (선택) 회사(단체)명, 회사(단체)전화번호</li>
                    </ul>

                    <p>2) 회사는 홈페이지를 통한 이용자의 각 신청서 작성을 통해 개인정보를 수집합니다.</p>
                </div>

                <div className={style.block}>
                    <h3>[개인정보 보유 및 이용기간]</h3>
                    <p>
                        회사는 이용자의 개인정보 수집 및 이용 동의일로부터 1년간 이용자의 개인정보를 처리 및 보유합니다. 단, 관계법령의 규정에 따라 개인정보를 보유하여야 할 필요가 있을 경우
                        일정기간 보유되며 이때 보유되는 개인정보의 열람 및 이용은 해당 사유로 제한됩니다.
                    </p>
                </div>

                <div className={style.block}>
                    <h3>[개인정보의 위탁 또는 제3자 제공]</h3>

                    <p>
                        1) 회사는 이용자의 개인정보를 타인 또는 타기업이나 기관 등 제3자에게 제공하지 않습니다.
                    </p>

                    <p>
                        2) 회사는 이용자의 개인정보를 위탁하고 있지 않습니다. 다만 추후 서비스 향상을 위하여 이용자의 개인정보를 위탁하여 처리하게 되는 경우 사전에 이를 고지하고 위탁 계약
                        등을 통하여 수탁자를 관리하도록 하겠습니다.
                    </p>
                </div>

                <div className={style.block}>
                    <h3>[개인정보의 파기 절차 및 방법]</h3>

                    <p>1) 회사는 원칙적으로 개인정보 처리 목적이 달성되어 개인정보 처리가 불필요하다고 인정되는 경우와 이용자가 개인정보의 파기를 요청한 경우에는 지체 없이 해당 개인정보를
                        파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.</p>
                    <p>2) 파기대상인 정보는 목적 달성 후 별도 DB 또는 서류함으로 옮겨져 내부 방침 및 기타 관련 법령에 따라 지체없이 파기됩니다. 이때 내부 방침에 따라 별도DB 또는 문서로
                        옮겨진 개인정보는 법률에 의한 경우를 제외하고는 이용되지 않습니다.</p>
                    <p>3) 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보처리 목적달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가
                        불필요하게 되었을 때에는 개인정보 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 파기합니다.</p>
                    <p>4) 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을, 종이에 출력된 정보는 분쇄기로 분쇄하여 파기합니다.</p>
                </div>

                <div className={style.block}>
                    <h3>[정보주체와 법정대리인의 권리 의무 및 그 행사방법에 관한 사항]</h3>

                    <p>
                        1) 정보주체는 회사에 대해 언제든지 아래 각 호의 권리를 행사할 수 있습니다.
                        <br/>
                        1. 개인정보 열람 요구권
                        <br/>
                        2. 개인정보 정정, 삭제 요구권
                        <br/>
                        3. 개인정보 처리정지 요구권
                        <br/>
                        4. 개인정보 수집, 이용 등에 대한 동의 철회
                    </p>

                    <p>
                        2) 정보주체는 개인정보의 열람/정정/삭제/처리정지 요구 또는 동의 철회, 파기 요청을 하고자 하는 경우 하단의 개인정보보호책임자에게 요청할 수 있으며 회사는 이에 지체없이
                        필요한 조치를 취하도록 하겠습니다.
                    </p>

                    <p>3) 1항 및 2항에 따른 권리 행사는 정보주체 본인이 하여야 하며, 정보주체의 법정대리인이나 위임을 받은 자 등 대리인이 행사하고자 하는 경우 회사는 위임장 등의 추가
                        서류를 요청할 수 있습니다.</p>

                    <p>4) 정보주체는 개인정보보호법령 등 관계법령을 위반하여 정보주체 본인 또는 타인의 개인정보를 사용하거나 침해하여서는 아니되며, 정보주체는 본인의 관리 하에 있는 정보를 본인의
                        책임 하에 관리하여야 합니다.</p>
                </div>

                <div className={style.block}>
                    <h3>[개인정보 처리방침의 고지 또는 통지 방법]</h3>

                    <p>회사는 이용자가 동의한 범위를 벗어나는 목적으로 개인정보를 이용하거나 제3자에게 제공하는 경우 개별적으로 이용자의 추가 동의를 얻도록 하겠습니다. 회사는 타인에게 개인정보의
                        처리를 위탁하는 경우 홈페이지 개인정보 처리방침을 통하여 사전에 이를 고지하도록 하겠습니다.</p>
                </div>

                <div className={style.block}>
                    <h3>[개인정보 보호를 위한 기술적 관리적 대책]</h3>

                    <p>
                        1) 내부관리계획의 수립 및 시행 : 회사는 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
                    </p>

                    <p>
                        2) 해킹 등에 대비한 기술적 대책 : 회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 방지하기 위하여 보안프로그램을 설치하고 주기적인 갱신, 점검 등을
                        수행하고 있습니다.
                    </p>

                    <p>
                        3) 개인정보 처리 직원의 교육 : 개인정보 관련 처리 직원은 최소한의 인원으로 구성되며, 개인정보보호 의무에 관한 정기적인 교육과 내부 절차를 마련하고 있습니다.
                    </p>
                </div>

                <div className={style.block}>
                    <h3>[개인정보보호책임자]</h3>

                    <p>
                        회사는 이용자의 개인정보를 보호하고 고충처리를 위하여 개인정보 보호책임자를 정하고 있습니다.
                        <br/>
                        - 이름 : 박종우
                        <br/>
                        - 직책 : 대표
                        <br/>
                        - 연락처 : <a href="tel:031-388-7575">031-388-7575</a>
                        <br/>
                        - 이메일 : <a href="mailto:contact@sungyun.kr">contact@sungyun.kr</a>
                    </p>
                </div>

                <div className={style.block}>
                    <p>
                        본 개인정보처리방침은 2019년 2월 1일 제정되었습니다. 본 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경 내용의 추가, 삭제, 정정이 있는 경우
                        변경사항의 시행일 7일 전부터, 이용자에게 불리하거나 중요한 내용의 경우 시행일 30일 전부터 고지하도록 하겠습니다.
                    </p>
                </div>
            </div>
        </ComponentContainer>
    );
};

export default PrivatePolicy;