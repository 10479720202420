import React, {useContext, useEffect, useState} from "react";
import { useParams, Link } from "react-router-dom";
import style from "./InquriyRead.module.scss";
import {FaFile} from "react-icons/all"
import {createBrowserHistory} from 'history';
import { Base64 } from 'js-base64';
import {InquiryDelete} from "../Delete/InquiryDelete";

import ComponentContainer from "../../../../global/ComponentContainer/ComponentContainer";
import {headerConfig, queryLink, unknownError} from "../../../../Function/sendQuery";
import {DeviceContext} from "../../../../../App";

const axios = require('axios').default;

const history = createBrowserHistory({
    forceRefresh: true
});

const InquiryRead = () => {
    const token = localStorage.getItem("token");
    const isAdmin = token.slice(0,6) === "Bearer" ? Base64.decode(token.slice(6).split('.')[1]).slice(-5, -1) === "true" : false;

    const device = useContext(DeviceContext) || 't';
    const {id} = useParams();
    const [article, setArticle] = useState({});

    useEffect(() => {
        if (token) {
            axios({
                method: "GET",
                url: queryLink + `/inquiry/${id}`,
                headers: {
                    ...headerConfig,
                    Authorization: localStorage.getItem("token")
                }
            })
                .then(function (response) {
                    if (response.status === 200) {
                        const {
                            name,
                            phone,
                            companyName,
                            companyPhone,
                            title,
                            articleClass,
                            content,
                            file,
                            answer,
                            created_at,
                            inquiryId,
                            email
                        } = response.data;
                        setArticle({
                            name,
                            phone,
                            companyName,
                            companyPhone,
                            title,
                            articleClass,
                            content,
                            file,
                            answer,
                            created_at,
                            inquiryId,
                            email
                        });
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        const {data, status} = error.response;
                        switch (status) {
                            case 400 :
                                alert("잘못된 요청입니다.");
                                history.goBack();
                                break;
                            case 401 :
                                alert("이 글을 읽을 권한이 없습니다.");
                                history.replace("/contact/inquiry/");
                                break;
                            default :
                                alert("알 수 없는 오류가 발생했습니다.");
                                console.error(status);
                                console.error(data);
                                history.goBack()
                        }
                    } else {
                        console.error(error);
                        alert(unknownError);
                    }
                });
        } else {
            alert("유저 토큰의 데이터가 없습니다. 다시 로그인해주세요.");
            history.replace("/contact/inquiry/");
        }
        // eslint-disable-next-line
    }, []);

    const config = {
        title: "Inquiry",
        sectionID: "Inquiry",
        bgImg: "InquiryMypage"
    };

    return (
        <ComponentContainer config={config}>
            <div className={style.CONTAINER}>
                <div className={style.userInfoContainer}>
                    <div className={style.infoColumn}>
                        <div className={style.key}>작성자</div>
                        <div className={style.value}>{article.name}</div>
                    </div>
                    <div className={style.infoColumn}>
                        <div className={style.key}>전화번호</div>
                        <div className={style.value}><a href={`tel:${article.phone}`}>{article.phone}</a></div>
                    </div>
                    <div className={style.infoColumn}>
                        <div className={style.key}>업체명</div>
                        <div className={style.value}>{article.companyName}</div>
                    </div>
                    <div className={style.infoColumn}>
                        <div className={style.key}>회사 번호</div>
                        <div className={style.value}><a href={`tel:${article.companyPhone}`}>{article.companyPhone}</a></div>
                    </div>
                    <div className={style.infoColumn}>
                        <div className={style.key}>이메일</div>
                        <div className={style.value}><a href={`mailto:${article.email}`}>{article.email}</a></div>
                    </div>
                </div>

                <div className={style.articleInfoContainer}>
                    <div className={style.header}>
                        <div>No.</div>
                        <div>분류</div>
                        {device === "p" ? <div>제목</div> : ""}
                        <div>날짜</div>
                    </div>

                    <div className={style.article}>
                        <div>{id}</div>
                        <div>{article.articleClass}</div>
                        {device === "p" ? <div>{
                            article.title}
                            {article.answer
                                ? <span className={`${style.isAnswered}`}>답변완료</span>
                                : <span className={`${style.isAnswered} ${style.false}`}>답변대기</span>
                            }
                        </div> : ""}
                        <div>{article.created_at}</div>
                    </div>
                </div>

                {device !== "p" ?
                <div className={style.titleContainer}>
                    <div className={style.key}>제목</div>

                    <div className={style.value}>{article.title}
                        {article.isAnswered
                            ? <span className={`${style.isAnswered}`}>답변완료</span>
                            : <span className={`${style.isAnswered} ${style.false}`}>답변대기</span>
                        }
                    </div>
                </div>
                    : ""}

                <div className={style.asideContainer}>
                    {article.file && device === "p" ? <div className={style.file}>
                        <div className={style.key}>첨부파일</div>
                        {/*TODO : 라이브 서버 링크로 바꿔주기*/}
                        <div className={style.value}><a href={`${queryLink}/files/${article.file}`} download="true"><FaFile/> {article.file}</a></div>
                    </div> : ""}

                    {article.answer ? <div className={style.answer}>
                        <div className={style.key}>답변</div>
                        <div className={style.value}>{article.answer}</div>
                    </div> : ""}
                </div>

                <article className={style.content}>
                    {article.content}
                </article>

                {article.file && device !== "p" ?
                    <div className={style.asideContainer}>
                        <div className={style.file}>
                            <div className={style.key}>첨부파일</div>
                            <div className={style.value}><a href={article.file}><FaFile/> {article.file}</a></div>
                        </div>
                    </div>: ""}

                <div className={style.linkContainer}>
                    <Link to="/contact/inquiry/write" className={style.link}>새로운 글</Link>
                    <Link to="/contact/inquiry" className={style.link}>목록</Link>

                    {!article.isAnswered && !isAdmin ? <Link to={`/contact/inquiry/modify/${id}`} className={style.link}>수정하기</Link> : ""}
                    {!article.isAnswered ? <div className={style.link} onClick={() => {InquiryDelete(id)}}>삭제하기</div> : ""}
                    {isAdmin ? <Link to={`/contact/inquiry/answer/${id}`} className={style.link}>답변하기</Link> : ""}
                </div>
            </div>
        </ComponentContainer>
    );
};

export default InquiryRead;