/*global kakao*/
import React, {useEffect} from "react";
import "./ContactDirections.scss";

import ComponentContainer from "../../../global/ComponentContainer/ComponentContainer";
import Table from "../../../global/Table/Table";

import {FiSearch} from "react-icons/all";

const ContactDirections = () => {
    const config = {
        title: "Maps and Direction",
        sectionID: "Direction",
        bgImg: "Map"
    };

    const table = {
        header: {
            name: "주소",
            child: [
                {
                    name: "(12925) 경기도 하남시 미사대로 540, B동 911호",
                    child: []
                }
            ]
        },
        child: [
            {
                name: "전화번호",
                code: "tel",
                child: [
                    {
                        name: <a href="tel:0313887575">031-388-7575</a>,
                        child: []
                    }
                ]
            },
            {
                name: "팩스",
                code: "tel",
                child: [
                    {
                        name: <a href="tel:031-388-7576">031-388-7576</a>,
                        child: []
                    }
                ]
            },
            {
                name: "대표 메일",
                code: "tel",
                child: [
                    {
                        name: <a href="mailto:jwpark@sungyun.kr">jwpark@sungyun.kr</a>,
                        child: []
                    }
                ]
            },
            {
                name: "대중교통 아용 안내",
                child: [
                    {
                        name: "현대지식산업센터.R6주거단지 (40m) 83, 마을 3-1",
                        child: []
                    },
                    {
                        name: "삼부르네상스 (235m) 마을 2, 마을 3",
                        child: []
                    },
                    {
                        name: "5호선 하남풍산역 1번출구 도보 10분",
                        child: []
                    }
                ]
            }
        ]
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src =
          "https://dapi.kakao.com/v2/maps/sdk.js?appkey=646213e9106506885a4af7aa21bc1aef&autoload=false";
        document.head.appendChild(script);

        script.onload = () => {
            kakao.maps.load(() => {
                let el = document.getElementById('map');
                // eslint-disable-next-line
                let map = new kakao.maps.Map(el, {
                    center: new kakao.maps.LatLng(37.5577069988444, 127.20584999351),
                    level: 4
                });

                const markerPosition = new kakao.maps.LatLng(37.5577069988444, 127.20584999351);

                const marker = new kakao.maps.Marker({
                    position: markerPosition
                });

                marker.setMap(map);
            });
        };
    });

    return (
        <ComponentContainer config={config}>
            <div className="container">
                <div className="App" id="map"/>

                <Table data={table} className={"DirectionTable"} />

                <div className="LinkWrap">
                    <a href="http://kko.to/Gi8pxJ100"><FiSearch /> 카카오 지도에서 보기</a>
                    {/*TODO: 지도 인쇄하기 기능 만들기*/}
                    {/*<a href="javascript:window.print()">지도 인쇄하기</a>*/}
                </div>
            </div>
        </ComponentContainer>
    );
};

export default ContactDirections;
