import React from "react";
import {createBrowserHistory} from 'history';
import {Link} from "react-router-dom";
const history = createBrowserHistory({
    forceRefresh: true
});

const LogOut = () => {
    const confirmLogout = window.confirm("정말로 로그아웃 하시겠습니까?");

    if (confirmLogout) {
        localStorage.removeItem("token");
        alert("로그아웃이 완료되었습니다.");
        history.replace("/");
    } else {
        history.goBack();
    }

    return (
        <div>
            만약, 메인으로 넘어가지 않을 경우

            <Link to="/">이곳</Link> 을 클릭하세요.
        </div>
    );
};

export default LogOut;