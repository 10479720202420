import React from "react";
import style from "./SiteMap.module.scss";
import {Link} from "react-router-dom";

import ComponentContainer from "../../global/ComponentContainer/ComponentContainer";

const SiteMap = () => {
    const config = {
        title : "Sitemap",
        sectionID : "sitemap",
        bgImg : "SiteMapsAdministrator"
    };

    return (
        <ComponentContainer config={config}>
            <div className={style.container}>
                <div className={style.column}>
                    <div className={style.row}>
                        <h3>About SY</h3>

                        <Link to="/about/greeting">Greetings</Link>
                        <Link to="/about/CI">Corporate Identity</Link>
                    </div>

                    <div className={style.row}>
                        <h3>Contact</h3>

                        <Link to="/contact/direction">Maps and Direction</Link>
                        <Link to="/contact/inquiry">Inquiry</Link>
                    </div>
                </div>

                <div className={style.column}>
                    <div className={style.row}>
                        <h3>Products</h3>

                        <Link to="/product/release_coating">Release Coating</Link>
                        <Link to="/product/personal_care">Personal Care</Link>
                        <Link to="/product/specialties">specialties</Link>
                        <Link to="/product/silane">SILANE</Link>
                        <Link to="/product/oil_polymer">Oil & Polymer</Link>
                        <Link to="/product/oligomers_gums">Oligomers & Gums</Link>
                        <Link to="/product/mmr">MMR</Link>
                        <Link to="/product/sealing_bonding">Sealing & Bonding</Link>
                        <Link to="/product/health_care">Health Care</Link>
                        <Link to="/product/textile_coating">Textile Coating</Link>
                    </div>
                </div>

                <div className={style.column}>
                    <div className={style.row}>
                        <h3>Career</h3>

                        <Link to="/Career">현재 준비중</Link>
                    </div>

                    <div className={style.row}>
                        <h3>이용안내</h3>

                        <Link to="/private-policy">개인정보취급방침</Link>
                    </div>
                </div>
            </div>
        </ComponentContainer>
    );
};

export default SiteMap;