import React, {useState} from "react";
import style from "./Register.module.scss";
import {autoHypen} from "../../../Function/autoHypen";
import {checkForm} from "./Feature/checkForm";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Register = () => {
    const [formValue, setFormValue] = useState({});

    const setFormVal = (name, value) => {
        setFormValue({
            ...formValue,
            [name]: value
        });
    };

    const handleChange = (e) => {
        const {name, checked, type} = e.target;
        const value = type === 'checkbox' ? checked : e.target.value;
        setFormVal(name, value);
    };

    const handleBirth = (e) => {
        const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 8);
        setFormVal("birthday", value);
    };

    const handleNumber = (e) => {
        const {name} = e.target;
        const value = autoHypen(e.target.value).slice(0, 13);
        setFormVal(name, value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        checkForm(formValue);
    };

    return (
        <section id="Register">
            <LazyLoadImage src="images/component/page_images/LoginRegister.jpg" alt="배경화면" className={style.bgImg} />
            <div className={style.container}>
                <h1 className={style.title}>Register</h1>

                <form className={style.form} onSubmit={handleSubmit}>
                    <label htmlFor="name" className={style.label}>이름 <b>*</b></label>
                    <input type="text" placeholder="이름을 입력해주세요." id="name" name="name" className={style.input}
                           required={true} onChange={handleChange}/>

                    <label htmlFor="email" className={style.label}>이메일 <b>*</b></label>
                    <input type="email" placeholder="이메일을 입력해주세요." id="email" name="email" className={style.input}
                           required={true} onChange={handleChange}/>

                    <label htmlFor="birthday" className={style.label}>생년월일 <b>*</b></label>
                    <input type="tel" placeholder="생년월일을 입력해주세요. 예시) 20001229" id="birthday" name="birthday" className={style.input} value={formValue.birthday || ""}
                           required={true} onChange={handleBirth}/>

                    <label htmlFor="phone" className={style.label}>전화번호 <b>*</b></label>
                    <input type="tel" placeholder="전화번호를 입력해주세요." id="phone" name="phone" className={style.input}
                           value={formValue.phone || ""}
                           required={true} onChange={handleNumber}/>

                    <label htmlFor="password" className={style.label}>6자 이상의 비밀번호 <b>*</b></label>
                    <input type="password" placeholder="비밀번호를 입력해주세요." id="password" name="password"
                           className={style.input} required={true} onChange={handleChange}/>

                    <label htmlFor="passwordCheck" className={style.label}>비밀번호 확인 <b>*</b></label>
                    <input type="password" placeholder="비밀번호를 다시 한번 입력해주세요." id="passwordCheck" name="passwordCheck"
                           className={style.input} required={true} onChange={handleChange}/>

                    <label htmlFor="CompanyName" className={style.label}>업체명</label>
                    <input type="text" placeholder="업체명을 입력해주세요." id="companyName" name="companyName"
                           className={style.input} onChange={handleChange}/>

                    <label htmlFor="companyPhone" className={style.label}>회사 전화번호</label>
                    <input type="tel" placeholder="회사의 전화번호를 입력해주세요." id="companyPhone" name="companyPhone" className={style.input}
                           value={formValue.companyPhone || ""}
                           onChange={handleNumber}/>

                    <label htmlFor="agreePolicy" className={style.agreePolicyText}>
                        <input type="checkbox" className={style.checkbox} id="agreePolicy" name="agreePolicy"
                               onChange={handleChange}/>
                        <span className={style.checkBoxButton}/>
                        이용약관 및 개인정보 처리방침에 동의합니다.
                    </label>

                    <button type="submit" className={style.button}>회원가입</button>
                </form>
            </div>
        </section>
    );
};

export default Register;