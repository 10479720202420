import React from "react";
import style from "./InquiryListItem.module.scss";
import {Link} from "react-router-dom";

const InquiryListItem = (props) => {
    const {inquiryId, title, articleClass, isAnswer, created_at, isUser} = props.data;

    return (
        <Link to={isUser
            ? `/contact/inquiry/${inquiryId}`
            : `/contact/inquiry/password/${inquiryId}`
        } className={style.link}>
            <div className={style.row}>
                <div className={style.column}>{inquiryId}</div>
                <div className={style.column}>{articleClass}</div>
                <div className={style.column}>
                    {title}
                    {isAnswer
                        ? <span className={`${style.isAnswered}`}>답변완료</span>
                        : <span className={`${style.isAnswered} ${style.false}`}>답변대기</span>
                    }
                </div>
                <div className={style.column}>{created_at.slice(0, 10)}</div>
            </div>
        </Link>
    );
};

export default InquiryListItem;