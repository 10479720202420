import React, {useContext, useEffect, useState} from "react";
import Loading from "../../loading/loading";
import styles from "./ProductList.module.scss";

// children
import ProductItemContainer from "./ProductItemContainer/ProductItemContainer";
import ProductBar from "./ProductBar/ProductBar";
import {DeviceContext} from "../../../../App";

const axios = require("axios").default;

const ProductList = (props) => {
    const [item, setItem] = useState({});
    const [firstItem, setFirstItem] = useState(0);
    const [max, setMax] = useState(0);
    const [isLoad, setLoad] = useState(false);
    const device = useContext(DeviceContext) || 'p';

    useEffect(() => {
        if (!props.items) {
            setLoad(true);
            axios({
                method: "get",
                url: `/data/productList.json`
            })
                .then((res) => {
                    setItem(res.data);
                    setMax(res.data.length - 4);
                    setLoad(false);
                });
        }
        // eslint-disable-next-line
    }, []);

    // eslint-disable-next-line
    useEffect(() => {
        props.items && setItem(props.items);
        device === 'p' ? setMax(item.length - 4) : setMax(item.length - 3);
    });


    const config = {
        bar: {
            now: firstItem,
            max,
            min: 0
        },
        scroll: {
            now: firstItem,
            max
        }
    };

    const handleChange = (e) => {
        if (e !== firstItem && e > -1 && e < max + 1) {
            setFirstItem(e);
        }
    };

    if (isLoad) {
        return (
            <Loading/>
        );
    } else {
        return (
            <div id="ProductList" className={styles.productList}>
                {max >= 1 ? <style dangerouslySetInnerHTML={{
                        __html: `
input[type="range"]::-webkit-slider-thumb { width : calc(100% / ${max + 1}) !important }
input[type="range"]::-moz-range-thumb { width : calc(100% / ${max + 1}) !important }`
                    }}/>
                    : ""}
                <ProductItemContainer onChange={handleChange} item={item} config={config.scroll}/>
                {max >= 1 ? <ProductBar onChange={handleChange} config={config.bar}/> : ""}
            </div>
        );
    }
};

export default ProductList;