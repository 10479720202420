import {Link} from "react-router-dom";
import {FiChevronDown} from "react-icons/all";
import React, {useState} from "react";
import style from "./MobileMenu.module.scss";
import {Collapse} from 'react-collapse';

const MobileMenu = (props) => {
    const {name, href, hide, child : myChild} = props.props;

    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(!isOpen);
    };

    const handleClick = () => {
        props.onChange();
    };


    if (!hide) {
        return (
            <div className={style.menu}>
                {myChild.length > 0 ?
                    <div className={style.menuTitle} onClick={handleOpen}>
                        {name}

                        <div>
                            <FiChevronDown
                                className={`${style.icons} ${isOpen ? style.spin : ""}`}
                            />
                        </div>
                    </div>
                    : <Link to={href} className={style.menuTitle} onClick={handleClick}>
                        {name}
                    </Link>
                }

                {myChild.length > 0 ?
                    <Collapse isOpened={isOpen}>
                        {myChild.map((child, i) => <MobileMenu key={i} props={child} onChange={handleClick}/>)}
                    </Collapse>
                    : ""}
            </div>
        );
    } else {
        return ("");
    }
};

export default MobileMenu;