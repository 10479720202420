import React from "react";
import style from "./CorporateIdentity.module.scss";
import ComponentContainer from "../../../global/ComponentContainer/ComponentContainer";
import {FiChevronRight, FiDownload} from "react-icons/all";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CorporateIdentity = () => {
    const config = {
        title: "Corporate Identity",
        sectionID: "CorporateIdentity",
        bgImg: "CorporateIdentity"
    };

    return (
        <ComponentContainer config={config}>
            <div className={style.container}>
                <div className={style.text}>

                    <div className={style.title}>
                        화학으로 무한한 가능성을 만들다,
                        <br/>
                        성연인더스트리
                    </div>

                    <p className={style.summary}>
                        실리콘이라는 소재가 가지고 있는 장점 중 하나인 무한한 가능성을 여러 개의 조각으로 표현, 그 조각들에 다채로운 색상을 입혀 표현해 실리콘이 우리 생활에서 가지고 있는 다양한 의미를 추상적이고 효과적으로 표현함.
                    </p>

                </div>

                <article className={style.logo}>
                    <p className={style.subTitle}>
                        <FiChevronRight/> <b>로고</b> | 원본
                    </p>

                    <div className={style.logoWrap}>
                        <div className={style.Bright}>
                            <LazyLoadImage src="/images/component/corporate_identity/Logo/Color/Bright.svg" alt=""/>
                        </div>

                        <div className={style.Dark}>
                            <LazyLoadImage src="/images/component/corporate_identity/Logo/Color/Dark.svg" alt=""/>
                        </div>
                    </div>

                    <div className={style.linkWrap}>
                        <a href="/files/CI/성연인더스트리_원본_로고.ai" className={style.downloadLink} download><FiDownload/> AI 다운로드</a>
                        <a href="/files/CI/성연인더스트리_원본_로고.png" className={style.downloadLink} download><FiDownload/> PNG 다운로드</a>
                    </div>
                </article>

                <article className={style.logo}>
                    <p className={style.subTitle}>
                        <FiChevronRight/> <b>로고</b> | 그레이스케일
                    </p>

                    <div className={style.logoWrap}>
                        <div className={style.Bright}>
                            <LazyLoadImage src="/images/component/corporate_identity/Logo/GrayScale/Bright.svg" alt=""/>
                        </div>

                        <div className={style.Dark}>
                            <LazyLoadImage src="/images/component/corporate_identity/Logo/GrayScale/Dark.svg" alt=""/>
                        </div>
                    </div>

                    <div className={style.linkWrap}>
                        <a href="/files/CI/성연인더스트리_그레이스케일_로고.ai" className={style.downloadLink} download><FiDownload/> AI 다운로드</a>
                        <a href="/files/CI/성연인더스트리_그레이스케일_로고.png" className={style.downloadLink} download><FiDownload/> PNG 다운로드</a>
                    </div>
                </article>

                <article className={style.regulation}>
                    <p className={style.subTitle}>
                        <FiChevronRight/> <b>로고</b> | 공간규정 A | 기본 공간규정
                    </p>

                    <div className={style.container}>
                        <LazyLoadImage src="/images/component/corporate_identity/Regulation/A.svg" alt=""/>
                    </div>
                </article>

                <article className={style.regulation}>
                    <p className={style.subTitle}>
                        <FiChevronRight/> <b>로고</b> | 공간규정 B | 예외 공간규정</p>

                    <div className={style.container}>
                        <LazyLoadImage src="/images/component/corporate_identity/Regulation/B.svg" alt=""/>
                    </div>
                </article>

                <article className={style.paletteContainer}>
                    <p className={style.subTitle}>
                        <FiChevronRight/> <b>브랜드</b> | 컬러 팔레트
                    </p>

                    <div className={style.paletteWrap}>
                        <div className={style.palette}>
                            <div className={style.color} style={{background: "#FF7E55"}}/>
                            <div className={style.text}>
                                <p>R : 255 G : 126 B : 85</p>
                                <p>C : 0 Y : 65 M : 63 K : 0 </p>
                            </div>
                        </div>

                        <div className={style.palette}>
                            <div className={style.color} style={{background: "#FFC101"}}/>
                            <div className={style.text}>
                                <p>R : 255 G : 126 B : 85</p>
                                <p>C : 0 Y : 65 M : 63 K : 0 </p>
                            </div>
                        </div>

                        <div className={style.palette}>
                            <div className={style.color} style={{background: "#DDE12D"}}/>
                            <div className={style.text}>
                                <p>R : 255 G : 126 B : 85</p>
                                <p>C : 0 Y : 65 M : 63 K : 0 </p>
                            </div>
                        </div>

                        <div className={style.palette}>
                            <div className={style.color} style={{background: "#64E0C2"}}/>
                            <div className={style.text}>
                                <p>R : 255 G : 126 B : 85</p>
                                <p>C : 0 Y : 65 M : 63 K : 0 </p>
                            </div>
                        </div>

                        <div className={style.palette}>
                            <div className={style.color} style={{background: "#75DFE1"}}/>
                            <div className={style.text}>
                                <p>R : 255 G : 126 B : 85</p>
                                <p>C : 0 Y : 65 M : 63 K : 0 </p>
                            </div>
                        </div>

                        <div className={style.palette}>
                            <div className={style.color} style={{background: "#FF73A9"}}/>
                            <div className={style.text}>
                                <p>R : 255 G : 126 B : 85</p>
                                <p>C : 0 Y : 65 M : 63 K : 0 </p>
                            </div>
                        </div>
                    </div>
                </article>

                <article className={style.paletteContainer}>
                    <p className={style.subTitle}>
                        <FiChevronRight/> <b>브랜드</b> | 흑백 팔레트
                    </p>

                    <div className={style.paletteWrap}>
                        <div className={style.palette}>
                            <div className={style.color} style={{background: "#2D343C"}}/>
                            <div className={style.text}>
                                <p>R : 255 G : 126 B : 85</p>
                                <p>C : 0 Y : 65 M : 63 K : 0 </p>
                            </div>
                        </div>

                        <div className={style.palette}>
                            <div className={style.color} style={{background: "#717B84"}}/>
                            <div className={style.text}>
                                <p>R : 255 G : 126 B : 85</p>
                                <p>C : 0 Y : 65 M : 63 K : 0 </p>
                            </div>
                        </div>

                        <div className={style.palette}>
                            <div className={style.color} style={{background: "#BEBEBE"}}/>
                            <div className={style.text}>
                                <p>R : 255 G : 126 B : 85</p>
                                <p>C : 0 Y : 65 M : 63 K : 0 </p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </ComponentContainer>
    )
};

export default CorporateIdentity;