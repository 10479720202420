import {sha512} from "js-sha512";
import {sendQuery} from "../../../../../Function/sendQuery";

const checkForm = (data = {}) => {
    const {
        name,
        companyName,
        email,
        newPassword,
        checkNewPassword,
        phone,
        articleClass,
        title,
        content,
        isUser
    } = data;
    const {file, companyPhone} = data || {};

    const nameReg = /[가-힣a-zA-Z]/g;
    const companyNameReg = /[가-힣a-zA-Z0-9]/g;
    const emailReg = /^[0-9a-zA-Z]([-_.]|[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]|[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;
    const phoneReg = /[0-9]{2,4}-[0-9]{3,4}-[0-9]{3,4}/;

    if (!nameReg.test(name)) {
        alert("이름에는 한글과 영어만 들어갈 수 있습니다.");
        return false;
    }

    if (!companyNameReg.test(companyName)) {
        alert("회사에는 한글과 영어명, 그리고 숫자만 들어갈 수 있습니다.");
        return false;
    }

    if (!emailReg.test(email)) {
        alert("올바른 이메일의 형식이 아닙니다.");
        return false;
    }

    if (newPassword !== checkNewPassword && newPassword) {
        alert("비밀번호가 일치하지 않습니다.");
        return false;
    }

    if (!phoneReg.test(phone) || (!phoneReg.test(companyPhone) && companyPhone)) {
        alert("올바른 전화번호의 형식이 아닙니다.");
        return false;
    }

    if (!isUser) {
        localStorage.setItem("token", "Basic " + btoa(`${email}:${sha512(newPassword)}`))
    }

    if (isUser) {
        const query = {
            title,
            articleClass,
            content,
            file,
        };

        sendQuery("inquiryWrite", {Authorization: localStorage.getItem("token")}, query);
    } else {
        const query = {
            title,
            articleClass,
            content,
            file,
            name,
            phone,
            companyName,
            companyPhone,
        };

        sendQuery("inquiryWrite", {Authorization: localStorage.getItem("token")}, query);
    }
};

export {checkForm};