import {sendQuery} from "../../../../Function/sendQuery";

import {createBrowserHistory} from 'history';
const history = createBrowserHistory({
    forceRefresh: true
});

const InquiryDelete = (id) => {
    const token = localStorage.getItem("token");

    if (!token) {
        alert("권한이 없습니다.");
        history.goBack();
        return false;
    }

    const check = window.confirm("정말로 삭제하시겠습니까?");

    if (!check) {
        history.replace(`/contact/inquiry/${id}`);
        return false;
    }

    sendQuery("InquiryDelete", {Authorization : token}, {id}).then();

    return true;
};

export {InquiryDelete}