import React from "react";
import style from "./career.module.scss";
import {Link} from "react-router-dom";
import {FaChevronRight} from "react-icons/all";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import ComponentContainer from "../../global/ComponentContainer/ComponentContainer";

const Career = () => {
    const config = {
        title : "Career",
        sectionID : "Career",
        bgImg : "Career"
    };

    return (
        <ComponentContainer config={config}>
            <div className={style.container}>
                <div className={style.text}>
                    <h1 className={style.title}>페이지 준비중입니다.</h1>

                    <h3 className={style.subTitle}>이용에 불편을 드려 죄송합니다.</h3>

                    <p className={style.text}>
                        보다 나은 서비스 제공을 위하여 페이지 준비중에 있습니다.
                        <br/>
                        빠른 시일내에 서비스를 준비하여 찾아뵙겠습니다.
                    </p>

                    <Link to="/" className={style.link}>메인으로 <FaChevronRight /></Link>
                </div>

                <LazyLoadImage src="/images/component/about_career/career.svg" alt="" className={style.images} />
            </div>
        </ComponentContainer>
    );
};

export default Career;