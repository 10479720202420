import React from "react";
import style from "./loading.module.scss";

const Loading = () => {
    return (
        <div className={style.loading}>
            <h1>데이터를 로딩중입니다.</h1>
        </div>
    );
};

export default Loading;