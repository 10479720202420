import {sendQuery} from "../../../../../Function/sendQuery";

const checkForm = (data = {}) => {
    const {
        id,
        articleClass,
        title,
        content,
        file
    } = data;

    const query = {
        id,
        title,
        articleClass,
        content,
        file,
    };

    sendQuery("inquiryModify", {Authorization: localStorage.getItem("token")}, query);
};

export {checkForm};