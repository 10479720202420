import React from "react";
import "./MainPage.scss";

import MainSecIndex from "./Section/Index/MainSecIndex";
import MainProduct from "./Section/MainProduct/MainProduct";
import InfoSummary from "./Section/InfoSummary/InfoSummary";

const MainPage = () => {
    return (
        <section id="MainPage">
            <MainSecIndex />

            <InfoSummary />

            <MainProduct />
        </section>
    );
};

export default MainPage;