import React, {useState} from "react";
import style from "./NeedPassword.module.scss";
import {sha512} from "js-sha512";
import {createBrowserHistory} from 'history';

import ComponentContainer from "../../../../global/ComponentContainer/ComponentContainer";
import {headerConfig, queryLink, unknownError} from "../../../../Function/sendQuery";
import {useParams} from "react-router-dom";
import {Base64} from "js-base64";

const axios = require('axios').default;

const history = createBrowserHistory({
    forceRefresh: true
});

const NeedPassword = () => {
    const {id} = useParams();

    const [form, setForm] = useState({});

    const config = {
        title: "Inquiry",
        sectionID: "InquiryLogin",
        bgImg: "InquiryMypage"
    };

    const token = localStorage.getItem("token");

    const isAdmin = token && token.slice(0,6) === "Bearer" ? Base64.decode(token.slice(6).split('.')[1]).slice(-5, -1) === "true" : false;

    if (isAdmin) {
        history.replace(`/contact/inquiry/${id}`);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        localStorage.setItem("token", "Basic " + btoa(`${form.email}:${sha512(form.password)}`));
        axios({
            method: "GET",
            url: queryLink + `/inquiry/${id}`,
            headers: {
                ...headerConfig,
                Authorization: localStorage.getItem("token")
            }
        })
            .then(function (response) {
                if (response.status === 200) {
                    history.replace(`/contact/inquiry/${id}`);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    const {data, status} = error.response;
                    switch (status) {
                        case 400 :
                            alert("잘못된 요청입니다.");
                            break;
                        case 401 :
                            alert("유저 정보가 일치하지 않습니다.");
                            break;
                        default :
                            alert("알 수 없는 오류가 발생했습니다.");
                            console.error(status);
                            console.error(data);
                    }
                } else {
                    console.error(error);
                    alert(unknownError);
                }
            });
        return false;
    };

    const handleInput = (e) => {
        const {name, value} = e.target;
        setForm({
            ...form,
            [name] : value
        })
    };

    return (
        <ComponentContainer config={config}>
            <div className={style.container}>
                <h1 className={style.title}>이메일과 비밀번호를 입력해주세요.</h1>

                <form onSubmit={handleSubmit} className={style.form}>
                    <input type="email" className={style.input} name="email" placeholder="이메일을 입력해주세요." onChange={handleInput} />
                    <input type="password" className={style.input} name="password" placeholder="비밀번호를 입력해주세요." onChange={handleInput} />

                    <button className={style.button} type="submit">로그인하기</button>
                </form>
            </div>
        </ComponentContainer>
    );
};

export default NeedPassword;