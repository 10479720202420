import React from "react";
import { Switch, Route } from "react-router-dom";
import InquiryWrite from "./Write/InquiryWrite";
import InquiryRead from "./Read/InquiryRead";
import InquiryList from "./List/InquiryList";
import NeedPassword from "./Need Password/NeedPassword";
import InquiryModify from "./Modify/InquiryModify";
import InquiryAnswer from "./Answer/InquiryAnswer";

const InquiryRouter = () => {
    return (
        <Switch>
            <Route exact path="/contact/inquiry" component={InquiryList} />
            <Route path="/contact/inquiry/write" component={InquiryWrite} />
            <Route path="/contact/inquiry/password/:id" component={NeedPassword} />
            <Route path="/contact/inquiry/answer/:id" component={InquiryAnswer} />
            <Route path="/contact/inquiry/modify/:id" component={InquiryModify} />
            <Route path="/contact/inquiry/:id" component={InquiryRead} />
        </Switch>
    );
};

export default InquiryRouter;