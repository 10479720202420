import React, {useContext} from "react";
import {DeviceContext} from "../../../../../App";
import {FiArrowLeft, FiArrowRight} from "react-icons/fi";
import "./ProductBar.scss";

const ProductBar = (props) => {
    const device = useContext(DeviceContext) || 't';
    const {now, min, max} = props.config;

    // Product Value 바꾸기
    const productBtnMinus = () => now > 0 ? props.onChange(now - 1) : 0;
    const productBtnPlus = () => now < max ? props.onChange(now + 1) : 0;
    const productBarMove = (e) => e.target.value > now ? productBtnPlus() : productBtnMinus();

    // DOM 엘리먼트
    const ButtonMinus = <button onClick={productBtnMinus} className="btnMinus" aria-label="Move Left"><FiArrowLeft/></button>;
    const ButtonPlus = <button onClick={productBtnPlus} className="btnPlus" aria-label="Move Right"><FiArrowRight/></button>;
    const Bar = <label><input type="range" step="1" min={min} max={max} value={now} onChange={productBarMove} /></label>;

    return device === 'm' ?
        <div id="ProductBar">{ButtonMinus}{Bar}{ButtonPlus}</div>
        : <div id="ProductBar">{Bar}{ButtonMinus}{ButtonPlus}</div>;
};

export default ProductBar;