import React from "react";
import "./TableColumn.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const TableColumn = (props) => {
    const {name, child, type = "text", src, width, height, align, code} = props.items;
    let {isLast} = props;

    if (!child || child.length === 0) {
        isLast = true;
    }

    return (
        <div className={isLast ? "column last" : "column"} id={code}>
            {type === "text" ?
                <span style={{width : `${width}px` || "120px"}}>{name}</span>
                : <LazyLoadImage src={src} alt="상품 이미지" style={{width : `${width}px` || "auto", height : `${height}px` || "auto"}} />}


            <div className={"container"} style={{width: `calc(100% - ${width || 120}px) ${align ? `text-align : center` : ""}`}}>
                {!child || child.length === 0 ? "" : child.map((child, i) => <TableColumn items={child} key={i}/>)}
            </div>
        </div>
    )
};

export default TableColumn;