const autoHypen = (value) => {
    value = value.replace(/[^0-9]/g, '');
    const char = "-";
    if (value.length < 4) {
        return value;
    } else if (value.length < 7) {
        return value.substr(0,3) + char + value.substr(3);
    } else {
        return value
            .replace(/[^0-9]/g, "")
            .replace(/(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})/,"$1-$2-$3")
            .replace(char + char, char)
    }
};

export {autoHypen};