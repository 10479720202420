import React, {useRef, useEffect, useContext} from "react";
import ProductItem from "./ProductItem/ProductItem";
import "./ProductItemContainer.scss";
import {DeviceContext} from "../../../../../App";
import Swipe from 'react-easy-swipe';

const ProductItemContainer = (props) => {
    const device = useContext(DeviceContext) || 't';
    const itemList = props.item || {};
    const {now, max} = props.config;

    const ItemContainer = useRef(null);
    const ItemContainerInner = useRef(null);

    const vw = window.innerWidth / 100;
    const width = device === "p"
        ? 187
        : device === "t"
            ? vw * 29.81771
            : vw * 29.6;

    let array = [];

    const handleResize = () => {
        array.push(0);
        for (let i = 1; i < max; i++) {
            array.push(Number(Math.round(width * i)));
        }
    };

    const handleStart = () => {
        document.body.style.overflowY = "hidden";
    };

    const handleEnd = () => {
        document.body.style.overflowY = "auto";
    };

    const handleMove = (position) => {
        const range = width;

        if (position.x > range) {
            props.onChange(now - 1);
        } else if (position.x < -range) {
            props.onChange(now + 1);
        }
    };

    useEffect(() => {
        ItemContainer.current.addEventListener("resize", handleResize);
        handleResize();
    });

    return (
        <Swipe
            onSwipeStart={handleStart}
            onSwipeMove={handleMove}
            onSwipeEnd={handleEnd}>
            <div id="ProductItemContainer" ref={ItemContainer}>
                <div style={{
                    transform : `translateX(-${width * now}px)`,
                    display: "flex",
                    transition : "transform .3s ease"}} ref={ItemContainerInner}>

                    {itemList.length > 0
                        ? itemList.map((item, i) => <ProductItem data={item} index={i} key={i}/>)
                        : ""}

                </div>
            </div>
        </Swipe>
    );
};

export default ProductItemContainer;