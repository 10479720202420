import React from "react";
import "./ProductInfoResult.scss";
import Table from "../../../../global/Table/Table";
import {LazyLoadImage} from 'react-lazy-load-image-component';

const ProductInfoResult = (props) => {
    const {name, summary, image, product} = props.info;

    return (
        <section id="ProductInfoResult">
            <div className="infoContainer">
                <div className="text">
                    <h3 className="infoTitle">{name}</h3>
                    <p className="infoSummary">{summary}</p>
                </div>

                <LazyLoadImage src={`/images/component/product_images/${image}.png`} alt="상품 이미지"/>
            </div>

            {product ? product.map((items, i) => <div key={i} className="tableWrapper"><Table data={items} className="resTable"/>
            </div>) : ""}
        </section>
    );
};

export default ProductInfoResult;