import React from "react";
import style from "./MyPageItems.module.scss"

const MyPageItem = (props) => {
    const {value, name, type, id, onValueChange, disable = false} = props;

    const handleInput = (e) => {
        onValueChange(e);
    };

    return (
        <div className={style.inputRow}>
            <label htmlFor="name">{name}</label>

            <input type={type || "text"} id={id} value={value || ""} onChange={handleInput} name={id} disabled={disable} />
        </div>
    );
};

export default MyPageItem;