import React from "react";
import {Link} from "react-router-dom";
import style from "./Footer.module.scss";
import {LazyLoadImage} from 'react-lazy-load-image-component';

const Footer = () => {
    return (
        <footer className={style.footer}>
            <div className={style.container}>
                <ul className={style.list}>
                    <li><Link to="/private-policy">개인정보취급방침</Link></li>
                    <li><Link to="/sitemap">사이트맵</Link></li>
                </ul>

                <p className={style.address}>
                    (12925) 경기도 하남시 미사대로 540, B동 911호
                </p>

                <ul className={style.links}>
                    <li>전화번호 : <a href="tel:031-388-7575">031-388-7575</a></li>
                    <li>팩스 : <a href="tel:031-388-7576">031-388-7576</a></li>
                    <li>대표 이메일 : <a href="mailto:jwpark@sungyun.kr">jwpark@sungyun.kr</a></li>
                </ul>

                <p className={style.ccl}>
                    2020 by Sungyun Industry Co.
                </p>
            </div>

            <LazyLoadImage src="/images/footer.svg" alt="성연인더스트리 푸터 로고" className={style.images}/>
        </footer>
    );
};

export default Footer;
