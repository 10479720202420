import React from "react";
import TableColumn from "./Column/TableColumn.js";
import "./TableRow.scss";

const TableRow = (props) => {
    const {items, className} = props;

    return (
        <div className={className}>
            <TableColumn items={items} />
        </div>
    );
};

export default TableRow;