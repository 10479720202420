import React, {useEffect, useState} from "react";
import styles from "./SearchResult.module.scss";
import classNames from "classnames/bind";

// 기능들
import {useParams} from "react-router-dom";
import axios from "axios";

// 추가 컴포넌트
import ComponentContainer from "../../../global/ComponentContainer/ComponentContainer";
import ProductList from "../../../global/Product/ProductList/ProductList";
import Loading from "../../../global/loading/loading";
import SearchItems from "./SearchItems/SearchItems";

// 아이콘 외
import {FiChevronRight} from "react-icons/all";

// 변수명 외
import {queryLink} from "../../../Function/sendQuery";
import ProductSearch from "../../../global/Product/ProductSearch/ProductSearch";

// SCSS Module Bind
const cx = classNames.bind(styles);

const SearchResult = () => {
    // 검색 결과 저장용
    const [result, setResult] = useState({});
    const [isLoad, setLoad] = useState(false);

    // searchKeyword
    const {searchWord} = useParams();

    // 검색 데이터 불러오기
    useEffect(() => {
        axios.get(`${queryLink}/product/api/search?search=${searchWord}`)
            .then(res => {
                setResult(res.data.data);
                setLoad(true);
            })
            .catch(err => {
                console.error(err);
            });
    }, [searchWord]);

    // 컴포넌트 설정
    const config = {
        title: `${searchWord}에 대한 검색 결과`,
        sectionID: "ProductSearchResult",
        bgImg: "Product"
    };

    if (!isLoad) {
        return (
            <ComponentContainer config={config}>
                <Loading />
            </ComponentContainer>
        );
    } else {
        return (
            <ComponentContainer config={config}>
                <div className={cx('productHelp')}>
                    <ProductSearch />
                    <ProductList/>
                </div>

                <section className={cx('result')}>
                    <p className={cx('counter')}>
                        <FiChevronRight/> <b className={cx('bold')}>전체</b> | 총 {result.length}건
                    </p>

                    <div className={cx('container')}>
                        {result.length > 0 &&result.map((data, i) => <SearchItems data={data} key={i}/>)}
                        {result.length === 0 && <div className={cx('noData')}>
                            <h1>검색하신 내용을 찾을 수 없습니다.</h1>
                        </div>}
                    </div>
                </section>
            </ComponentContainer>
        );
    }

};

export default SearchResult;