import React from "react";
import style from "./ComponentContainer.module.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ComponentContainer = (props) => {
    const {title, sectionID, bgImg} = props.config || "";

    return (
        <section id={sectionID}>
            <aside className={style.currentRoute}>
                <LazyLoadImage src={`/images/component/page_images/${bgImg}.jpg`} alt="배경화면" />
                <h1>{title}</h1>
            </aside>

            <article className={style.article}>
                <div className={style.container}>
                    {props.children}
                </div>
            </article>
        </section>
    );
};

export default ComponentContainer;