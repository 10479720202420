import React from "react";
import "./ProductItem.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {createBrowserHistory} from 'history';

const history = createBrowserHistory({
    forceRefresh: true
});

const ProductItem = (props) => {
    const {code, image, name, nameEn} = props.data;

    const handleMove = () => {
        history.push(`/product/${code}`);
    };

    return (
        <div onClick={handleMove} className="products">
            <div className="productItemImg">
                {props.index !== 0 ? <LazyLoadImage src={`/images/component/product_images/${image}.png`} alt={name} /> : <img src={`/images/component/product_images/${image}.png`} alt={name} />}
            </div>
            <div className="text">
                <div className="productItemNameEN">{nameEn}</div>
                <div className="productItemNameKO">{name}</div>
            </div>
        </div>
    );
};

export default ProductItem;