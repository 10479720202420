import React from "react";
import style from "./HeaderMenu.module.scss";
import {Link} from "react-router-dom";

const HeaderMenu = props => {
    const {index, data, onChange, hoverDisable} = props;

    const handleHover = () => {
        !hoverDisable && onChange(index);
    };

    return (
        <li className={style.items} onMouseOver={handleHover}><Link to={data.href}>{data.name}</Link></li>
    );
};

export default HeaderMenu;