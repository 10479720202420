import React from "react";
import {Link} from "react-router-dom";
import {MdChevronRight} from "react-icons/all";
import "./ExternalLink.scss";

const ExternalLink = (props) => {
    const href = props.href || "/#";
    const className = props.className || "";

    return (
        <Link to={href} className={className}>
            {props.children} <MdChevronRight />
        </Link>
    );
};

export default ExternalLink;