import React, {useEffect, useState} from "react";
import style from "./InquiryAnswer.module.scss";
import ComponentContainer from "../../../../global/ComponentContainer/ComponentContainer";
import {Base64} from "js-base64";
import {queryLink} from "../../../../Function/sendQuery";
import {useParams} from "react-router-dom";
import {createBrowserHistory} from "history";
import {FaFile} from "react-icons/all";

const history = createBrowserHistory({
    forceRefresh: true
});

const axios = require('axios').default;

const InquiryAnswer = () => {
    const {id} = useParams();
    const [answerText, setAnswerText] = useState("");
    const [question, setQuestion] = useState({});

    useEffect(() => {
        const token = localStorage.getItem("token") || "";
        const isAdmin = token.slice(0,6) === "Bearer" ? Base64.decode(token.slice(6).split('.')[1]).slice(-5, -1) === "true" : false;

        if (!token || !isAdmin) {
            alert("권한이 없습니다.");
            history.goBack();
            return false;
        }

        axios({
            method : "GET",
            url : `${queryLink}/inquiry/${id}`,
            headers: {
                Authorization: localStorage.getItem("token")
            }
        })
            .then((res) => {
                if (res.status === 200) {
                    const {title, articleClass, content, file, isAnswered} = res.data;
                    setQuestion({
                        title,
                        articleClass,
                        content,
                        file,
                        isAnswered
                    });

                    if (res.data.isAnswered) {
                        alert("이미 답변된 항목입니다.");
                        history.goBack();
                    }
                }
            })
            .catch((err) => {
                const {data} = err.response;
                if (err.response) {
                    const {status} = err.response;

                    if (status === 401) {
                        alert("권한이 없습니다.");
                        history.replace("/contact/inquiry");
                    } else {
                        alert("알 수 없는 오류가 발생했습니다.");
                        console.error(status);
                        console.error(data);
                    }
                } else {
                    alert("알 수 없는 오류가 발생했습니다.");
                    console.error(data);
                }
            });
        // eslint-disable-next-line
    }, []);

    const config = {
        title: "Inquiry",
        sectionID: "Inquiry",
        bgImg: "InquiryMypage"
    };

    const handleChange = (e) => {
        const {value} = e.target;
        setAnswerText(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (answerText === "") {
            alert("답변의 값은 공백일 수 없습니다.");
            return false;
        }

        const check = window.confirm("정말로 답변을 등록하시겠습니까? 한번 등록하면 절대 수정할 수 없습니다!");

        if (check) {
            axios({
                method : "POST",
                url : `${queryLink}/admin/answer/${id}`,
                headers: {
                    Authorization: localStorage.getItem("token")
                },
                data : {
                    answer : answerText
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        alert("답변 등록이 완료되었습니다.");
                        history.replace(`/contact/inquiry/${id}`);
                    }
                })
                .catch((err) => {
                    const {data} = err.response;
                    if (err.response) {
                        const {status} = err.response;

                        if (status === 401) {
                            alert("권한이 없습니다!");
                            history.replace("/contact/inquiry");
                        } else {
                            alert("알 수 없는 오류가 발생했습니다.");
                            console.error(status);
                            console.error(data);
                        }
                    } else {
                        alert("알 수 없는 오류가 발생했습니다.");
                        console.error(data);
                    }
                });
        }
    };

    return (
        <ComponentContainer config={config}>
            <div className={style.container}>
                <div className={style.titleContainer}>
                    <div className={style.key}>제목</div>
                    <div className={style.value}>{question.title} </div>
                </div>

                <div className={style.asideContainer}>
                    {question.file ? <div className={style.file}>
                        <div className={style.key}>첨부파일</div>
                        <div className={style.value}><a href={question.file}><FaFile/> {question.file}</a></div>
                    </div> : ""}
                </div>

                <article className={style.content}>
                    {question.content}
                </article>

                <form onSubmit={handleSubmit} className={style.form}>
                <textarea name="answer" className={style.textarea} onChange={handleChange} />
                    <div className={style.btnContainer}>
                        <button type="submit" className={style.submitBtn}>
                            답변하기
                        </button>
                    </div>
                </form>
            </div>
        </ComponentContainer>
    );
};

export default InquiryAnswer;