import React from "react";
import "./ProductItemList.scss";
import ComponentContainer from "../../../global/ComponentContainer/ComponentContainer";
import ProductList from "../../../global/Product/ProductList/ProductList";
import ProductSearch from "../../../global/Product/ProductSearch/ProductSearch";

const ProductItemList = () => {
    const config = {
        title: "Product",
        sectionID: "ProductInfo",
        bgImg: "Product"
    };

    return (
        <ComponentContainer config={config}>
            <div className="articleContainer">
                <ProductSearch />
                <ProductList/>
            </div>
        </ComponentContainer>
    );
};

export default ProductItemList;