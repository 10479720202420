import {sha512} from "js-sha512";
import {sendQuery} from "../../../../Function/sendQuery";

const checkForm = (data = {}) => {
    const {
        email,
        birthday,
        phone,
        companyPhone,
        agreePolicy
    } = data;

    const name = data.name;
    const companyName = data.companyName;
    const password = data.password;
    const passwordCheck = data.passwordCheck;

    const nameReg = /[a-zA-Z가-힣]|.,/g;
    const emailReg = /^[0-9a-zA-Z]([-_.]|[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]|[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;
    const companyNameReg = /[a-zA-Z가-힣0-9]|.,/g;
    const dateReg = /^(19|20)\d{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[0-1])$/;
    const phoneReg = /[0-9]{2,4}-[0-9]{3,4}-[0-9]{3,4}/;

    if (!nameReg.test(name)) {
        alert('이름에는 영어와 이름만 들어갈 수 있습니다.');
        return false;
    }

    if (!emailReg.test(email)) {
        alert('이메일의 형식이 맞지 않습니다.');
        return false;
    }

    if (!dateReg.test(birthday)) {
        alert('올바른 날짜 형식이 아닙니다. 19990428 처럼 입력해주세요.');
        return false;
    }

    if (!phoneReg.test(phone) || (companyPhone && !phoneReg.test(companyPhone))) {
        alert("올바른 전화번호의 형식이 아닙니다.");
        return false;
    }

    if (password.length < 6) {
        alert('비밀번호의 길이가 너무 짧습니다.');
        return false;
    }

    if (password !== passwordCheck) {
        alert('비밀번호가 일치하지 않습니다.');
        return false;
    }

    if (!companyNameReg.test(companyName) && companyName) {
        alert('회사 이름에는 숫자와 한글, 영어만 들어갈 수 있습니다.');
        return false;
    }

    if (!agreePolicy) {
        alert('이용약관 및 개인정보 처리방침에 동의해주세요!');
        return false;
    }

    const query = {
        name,
        email,
        birthday,
        phone,
        password : sha512(password),
        companyName,
        companyPhone
    };

    sendQuery("register", {}, query);
};

export {checkForm};