import React, {useState} from "react";
import {Link} from "react-router-dom";
import style from "./ResetPW.module.scss";
import {autoHypen} from "../../../Function/autoHypen";
import {sendQuery} from "../../../Function/sendQuery";

const ResetPW = () => {
    const [form, setForm] = useState({});

    const handleSubmit = e => {
        e.preventDefault();

        sendQuery("resetPW", {}, form);
    };

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name] : e.target.value
        })
    };

    const handleNumber = e => {
        setForm({
            ...form,
            [e.target.name] : autoHypen(e.target.value)
        })
    };

    console.log(form);

    return (
        <section id="ResetPW">
            <div className={style.container}>
                <p className={style.text}>
                    {`비밀번호를 재설정 할 이메일을 입력하세요.
자세한 안내가 담긴 메일을 보내드리겠습니다.`}
                </p>
                <form className={style.form} onSubmit={handleSubmit}>
                    <label htmlFor="" className={style.label}>비밀번호를 재설정할 계정의 이메일 <b>*</b></label>
                    <input type="email" className={style.input} name="email" required={true} placeholder="이메일을 입력하세요." onChange={handleChange} />

                    <label htmlFor="" className={style.label}>비밀번호를 재설정할 계정의 전화번호 <b>*</b></label>
                    <input type="tel" className={style.input} name="phone" required={true} placeholder="전화번호를 입력하세요." onChange={handleNumber} value={form.phone || ""} />
                    <button type="submit" className={style.button}>
                        재설정 이메일 보내기
                    </button>
                </form>
                <div className={style.text}>
                    <Link to="/login">로그인</Link>
                    <Link to="/private-policy">개인정보취급방침</Link>
                </div>
            </div>
        </section>
    )
};

export default ResetPW;