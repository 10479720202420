import React, {useState, createContext, useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import "./css/App.scss";

// Components
import Header from "./Components/Header/header";
import BodyRouter from "./Components/Body/BodyRouter";
import Footer from "./Components/Footer/Footer";

// Contexts
export const DeviceContext = createContext(null);

const App = () => {
    // 디바이스 너비 검색
    const [deviceType, setDeviceType] = useState("t");
    const hasResize = () => {
        let d = window.innerWidth < 425 ? 'm' : window.innerWidth < 1024 ? 't' : 'p';

        if (d !== deviceType) {
            setDeviceType(d);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", hasResize);
        hasResize();
    });

    return (
        <DeviceContext.Provider value={deviceType}>
            <Router>
                <Header />
                <BodyRouter />
                <Footer />
            </Router>
        </DeviceContext.Provider>
    );
};

export default App;