import React from "react";
import styles from "./SearchItems.module.scss";
import classNames from "classnames/bind";

// 기능들
import {Link} from "react-router-dom";

// SCSS Module Bind
const cx = classNames.bind(styles);

// 검색 결과의 타입 분류
const filterType = (name_type) => {
    switch (name_type) {
        case 'Big' : return "대분류";
        case 'Middle' : return "중분류";
        case 'Small' : return "소분류";
        case 'Product' : return "상품";
        default : return "기타";
    }
}

const SearchItems = props => {
    const {link, full_name : fullName, name_type} = props.data;
    const nameType = filterType(name_type);

    return (
        <article className={cx('item')}>
            <Link to={`/product/${link}`}>
                {fullName.map((word, i) => {
                    return i === fullName.length - 1
                        ? `${word}`
                        : `${word} / `
                })}
            </Link>
            <span className={cx('type')}>{nameType}</span>
        </article>
    );
};

export default SearchItems;