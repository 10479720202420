import React from "react";
import style from "./MainProduct.module.scss";
import {Link} from "react-router-dom";

import ProductList from "../../../../global/Product/ProductList/ProductList";
import ProductSearch from "../../../../global/Product/ProductSearch/ProductSearch";

const MainProduct = () => {
    return (
        <div className={style.MainProduct}>
            <div className={style.container}>
                <h2>제품 탐색</h2>
                <p>실리콘 오일부터 산업 전반에 이르기까지 적용 가능한 성연인더스트리의 다양한 실리콘 제품들을 살펴보세요.</p>

                <ProductSearch />

                <ProductList/>

                <Link to="/product" className={style.linkProduct}>+ 자세히 탐색하기</Link>
                <Link to="/contact/inquiry/write" className={style.linkProduct}>+ 제품 문의하기</Link>
            </div>
        </div>
    );
};

export default MainProduct;