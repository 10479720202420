import React, {useEffect, useState} from "react";
import "./ProductInfo.scss";
import ComponentContainer from "../../../global/ComponentContainer/ComponentContainer";
import ProductInfoResult from "./ProductInfoResult/ProductInfoResult";
import {useParams} from "react-router-dom";
import Loading from "../../../global/loading/loading";

import ProductList from "../../../global/Product/ProductList/ProductList";
import {createBrowserHistory} from "history";
import ProductSearch from "../../../global/Product/ProductSearch/ProductSearch";

const history = createBrowserHistory({
    forceRefresh: true
});


const axios = require('axios').default;

const ProductInfo = () => {
    const {productCode} = useParams();
    const {productDetailCode, groupCode, productNameCode} = useParams() || "";

    // 상품 목록
    const [productList, setProductList] = useState("");

    // 상품 메뉴
    const [productDetail, setProductDetail] = useState({});

    const [isLoad, setLoad] = useState(true);

    useEffect(() => {
        const config = {behavior: "smooth", block: "center", inline: "nearest"};

        if (groupCode && document.getElementById(groupCode)) {
            if (productNameCode) {
                document.getElementById(productNameCode).scrollIntoView(config);
                document.getElementById(productNameCode).className += " selected";
            } else {
                document.getElementById(groupCode).scrollIntoView(config);
                document.getElementById(groupCode).className += " selected";
            }
        }
    });

    useEffect(() => {
        // 대분류 데이터 불러오기 (상품 목록용)
        axios.get(`/data/${productCode}/${productCode}.json`)
            .then(res => {
                if (res.data.product.length > 1) {
                    setProductList(res.data.product);
                }

                // 첫번째 데이터 불러오기 (대분류 위치시)
                if (res.data.product.length > 1 && !productDetailCode) {
                    // console.log('1번째 데이터 불러오기');
                    const code = res.data.product[0].code;
                    axios.get(`/data/${productCode}/product/${code.split('/')[1]}.json`)
                        .then(res => {
                            setProductDetail(res.data);
                        })
                }
                // 해당 데이터 불러오기 (중분류 위치시)
                else if (productDetailCode) {
                    // console.log('중분류 데이터 불러오기');
                    axios.get(`/data/${productCode}/product/${productDetailCode}.json`)
                        .then(res => {
                            setProductDetail(res.data);
                        })
                }
                // 대분류 내 데이터가 1개인 경우 (그 외)
                else {
                    // console.log('그 외 데이터 불러오기');
                    axios.get(`/data/${productCode}/product/${productCode}.json`)
                        .then(res => {
                            setProductDetail(res.data);
                        })
                }
            })
            .catch(err => {
                if (err.response) {
                    const {status} = err.response;
                    if (status === 404) {
                        alert("요청하신 데이터를 찾을 수 없습니다.");
                        history.replace(`/product`);
                    } else {
                        alert("알 수 없는 오류가 발생했습니다.");
                    }
                } else {
                    alert("알 수 없는 오류가 발생했습니다.");
                }
            });

        setLoad(false);

        // eslint-disable-next-line
    }, [window.location.pathname]);

    const config = {
        title: productDetail.name,
        sectionID: "ProductInfo",
        bgImg: "Product"
    };

    if (!isLoad) {
        return (
            <ComponentContainer config={config}>
                <div className="articleContainer">
                    <ProductSearch />

                    {!productDetail.hideList && <ProductList items={productList}/>}

                    <ProductInfoResult info={productDetail}/>
                </div>
            </ComponentContainer>
        );
    } else {
        return (
            <ComponentContainer config={config}>
                <Loading/>
            </ComponentContainer>
        );

    }
};

export default ProductInfo;