import React from "react";
import { Switch, Route } from "react-router-dom";

import ProductItemList from "./ProductItemList/ProductItemList";
import ProductInfo from "./ProductInfo/ProductInfo";
import SearchResult from "./ProductSearchResult/SearchResult";

const ProductRouter = () => {
    return (
        <Switch>
            <Route exact path="/product" component={ProductItemList} />
            <Route path="/product/search/:searchWord" component={SearchResult} />
            <Route path="/product/:productCode/:productDetailCode/:groupCode/:productNameCode" component={ProductInfo} />
            <Route path="/product/:productCode/:productDetailCode/:groupCode" component={ProductInfo} />
            <Route path="/product/:productCode/:productDetailCode" component={ProductInfo} />
            <Route exact path="/product/:productCode" component={ProductInfo} />

        </Switch>
    );
};

export default ProductRouter;