import React from "react";
import style from "./Greeting.module.scss";
import ComponentContainer from "../../../global/ComponentContainer/ComponentContainer";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Greeting = () => {
    const config = {
        title: "Greeting",
        sectionID: "Greeting",
        bgImg: "AboutSY"
    };

    return (
        <ComponentContainer config={config}>
            <div className={style.container}>
                <div className={style.text}>
                    <h1 className={style.title}>{`Infinite Possibility`}</h1>
                    <h3 className={style.subTitle}>성연 인더스트리 홈페이지를 방문하여 주신
                        <br/>
                        모든 고객 여러분들을 진심으로 환영합니다.</h3>
                    <p className={style.text}>
                        21세기 실리콘은 모든 산업을 통틀어 쓰이지 않는 곳이 없을 만큼 그 적용 분야나 활용도에 있어 우리 인간과 아주 밀접한 관련을 맺고 있는 중요한 소재입니다. 또한 과거, 현재를 거쳐 더욱 진화될 미래의 필수적인 산업에 최고의 가치를 창출할 원천입니다. 성연인더스트리는 산업 전반에 사용되는 실리콘 원재료 및 제품들을 수입, 수출 및 주문생산 판매를 통한 전문 유통 및 컨설팅업체로서 고객 우선주의에 입각한 적재적소의 제품으로 기업이 최고의 부가가치를 창출하는데 초석이 될 수 있는 신뢰 있는 실리콘 전문기업으로 성장할 것을 약속드립니다.
                    </p>
                    <span className={style.sign}>
                        성연인더스트리 대표 <b>박종우</b>
                    </span>
                </div>

                <LazyLoadImage src="/images/component/about_career/sungyun.svg" alt="성연인더스트리" style={{ maxWidth: "50%" }} />
            </div>
        </ComponentContainer>
    );
};

export default Greeting;