import React from "react";
import items from "../headerItem.json";
import MobileMenu from "./MobileMenu/MobileMenu";
import style from "./MobileMenu.module.scss";

const MobileMenuList = (props) => {
    const {name} = props.userdata;
    const headerItem = items;
    let user = {};

    const handleChange = () => {
        props.onChange();
    };

    if (name) {
        user = [
            {
                "name": "About me",
                "href": "/#",
                "child": [
                    {
                        name,
                        "href": "/mypage",
                        "child": []
                    },
                    {
                        "name": "Logout",
                        "href": "/logout",
                        "child": []
                    }
                ]
            }
        ]
    }

    return (
        <nav className={style.mobileMenu}>
            {headerItem[0].map((child, i) => <MobileMenu key={i} props={child} onChange={handleChange}/>)}
            {name
                ? user.map((child, i) => <MobileMenu key={i} props={child} onChange={handleChange}/>)
                : headerItem[1].map((child, i) => <MobileMenu key={i} props={child} onChange={handleChange}/>)}
        </nav>
    );
};

export default MobileMenuList;