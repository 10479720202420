import React from "react";
import style from "./InfoSummary.module.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const InfoSummary = () => {
    return (
        <section className={style.InfoSummary}>
            <div className={style.container}>
                <div className={style.summary}>
                    <h2>Sungyun Product</h2>
                    <p>우리 실생활에 있어서 중요한 실리콘, 성연인더스트리가 취급하고 있는 실리콘 제품들을 확인해보세요.</p>
                    <LazyLoadImage src="/images/component/info_summary/graph.svg" alt="" />
                </div>

                <div className={style.summary}>
                    <h2>Sungyun Network</h2>
                    <p>성연인더스트리는 세계 여러 국가의 실리콘 제조회사와 공급 네트워크를 맺고 있습니다.</p>
                    <LazyLoadImage src="/images/component/info_summary/world.svg" alt="" />
                </div>
            </div>
        </section>
    );
};

export default InfoSummary;