import React from "react";
import styles from "./HeaderSubMenu.module.scss";
import classNames from "classnames/bind";
import {Link} from "react-router-dom";

import data from "../headerItem.json";

const cx = classNames.bind(styles);

const HeaderSubMenu = props => {
    const t = props.targetNum;

    if (t === -1 || !data[0][t]) {
        return false;
    }

    return <div className={cx('background')}>
        {data[0][t].child.map((item, i) => {
            return <Link key={i} to={item.href} className={cx('link')}>{item.name}</Link>
        })}
    </div>
};

export default HeaderSubMenu;