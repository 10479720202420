import React from "react";
import { Switch, Route } from "react-router-dom";

// MainPage
import MainPage from "./MainPage/MainPage";

// about_career SY
import AboutRouter from "./AboutSY/AboutRouter";

// Product
import ProductRouter from "./Product/ProductRouter";

// Contact
import ContactRouter from "./Contact/ConectRouter";

// Auth
import Login from "./Auth/Login/Login";
import ResetPW from "./Auth/ResetPW/ResetPW";
import Register from "./Auth/Register/Register";
import MyPage from "./Auth/MyPage/MyPage";
import LogOut from "./Auth/Logout/Logout";

// Career
import Career from "./Career/Career";

// ETC
import SiteMap from "./SiteMap/SiteMap";
import PrivatePolicy from "./etc/PrivatePolicy/PrivatePolicy";

const BodyRouter = () => {
    return (
        <Switch>
            {/*MainPage*/}
            <Route exact path="/" component={MainPage} />
            {/*Router*/}
            <Route path="/product" component={ProductRouter} />
            <Route path="/about" component={AboutRouter} />
            <Route path="/contact" component={ContactRouter} />
            {/*Auth*/}
            <Route path="/login" component={Login} />
            <Route path="/resetPW" component={ResetPW} />
            <Route path="/register" component={Register} />
            <Route path="/mypage" component={MyPage} />
            <Route path="/logout" component={LogOut} />
            {/*Career*/}
            <Route path="/career" component={Career} />
            {/*ETC*/}
            <Route path="/sitemap" component={SiteMap} />
            <Route path="/private-policy" component={PrivatePolicy} />


            <Route path="*">
                페이지를 찾지 못했습니다.
            </Route>
        </Switch>
    );
};

export default BodyRouter;