import React from "react";
import { Switch, Route } from "react-router-dom";

import Greeting from "./Greeting/Greeting";
import CorporateIdentity from "./CorporateIdentity/CorporateIdentity";

const About = () => {
    return (
        <Switch>
            <Route exact path="/about" component={Greeting} />
            <Route path="/about/greeting" component={Greeting} />
            <Route path="/about/CI" component={CorporateIdentity} />
        </Switch>
    );
};

export default About;