import React from "react";
import ExternalLink from "../../../../global/ExternalLink/ExternalLink";
import style from "./MainSecIndex.module.scss";

const MainSecIndex = () => {
    return (
        <div className={style.MainSecIndex}>
            <video src="/Video/bg.mp4" type="video/mp4"
                   autoPlay={true} loop={true} muted={true} className={style.video}
                   height="100%"
            />
            <div className={style.container}>
                <h1 className={style.title}>
                    {`Infinite
Possibility`}
                </h1>

                <p className={style.summary}>
                    {`화학으로 무한한 가능성을 만들다,
성연인더스트리`}
                </p>

                <ExternalLink className={style.link} href="/about/greeting">함께 하기</ExternalLink>
            </div>

            <div className={style.scroll}>
                <span className={style.scrollBody} />
            </div>
        </div>
    );
};

export default MainSecIndex;