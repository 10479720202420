import React, {useState} from "react";
import {sha512} from "js-sha512";
import {sendQuery} from "../../../Function/sendQuery";
import {Link} from "react-router-dom";
import style from "./Login.module.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
const Login = () => {
    const [formValue, setFormValue] = useState({
        "email" : "",
        "currentPassword" : ""
    });

    const handleSubmit = (e) => {
        const {email, currentPassword} = formValue;

        const emailReg = /^[0-9a-zA-Z]([-_.]|[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]|[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/;
        const pwReg = /([a-zA-Z0-9]|[!@#$%^&])*/g;

        if (!emailReg.test(email) || !pwReg.test(currentPassword)) {
            alert("이메일 또는 패스워드의 표현식이 맞지 않습니다.");
            return false;
        }

        const query = {
            "username" : email,
            "password" : sha512(currentPassword)
        };

        sendQuery("login", {}, query);

        setFormValue({
            email : "",
            currentPassword : ""
        });

        e.preventDefault();
    };

    const handleChange = (e) => {
        const {name, value} = e.target;

        setFormValue({
            ...formValue,
            [name] : value
        });
    };

    return (
        <section id="Login">
            <LazyLoadImage src="images/component/page_images/LoginRegister.jpg" alt="배경화면" className={style.bgImg} />
            <div className={style.container}>
                <h1 className={style.title}>Login</h1>

                <form className={style.form} onSubmit={handleSubmit}>
                    <input type="email" name="email" className={style.input} placeholder="이메일" required={true} onChange={handleChange} value={formValue.email} />
                    <input type="password" name="currentPassword" className={style.input} placeholder="비밀번호" required={true} onChange={handleChange} value={formValue.currentPassword} />

                    <button type="submit" className={style.button}>
                        로그인
                    </button>
                </form>

                <div className={style.text}>
                    <Link to="/register" className={style.link}>회원가입</Link>
                    <Link to="/resetPW" className={style.link}>비밀번호 재설정</Link>
                </div>
            </div>
        </section>
    );
};

export default Login;