import {sha512} from "js-sha512";
import {sendQuery} from "../../../../Function/sendQuery";

const checkForm = (data) => {
    const {
        name,
        companyName,
        newPassword,
        currentPassword,
        checkNewPassword,
        birthday,
        email,
        companyPhone,
        phone,
    } = data;

    const nameReg = /[a-zA-Z가-힣]|.,/g;
    const companyNameReg = /[a-zA-Z가-힣0-9]|.,/g;

    if (!nameReg.test(name)) {
        alert("올바르지 않은 사용자명입니다.");
        return false;
    }

    if (!companyNameReg.test(companyName)) {
        alert("올바르지 않은 회사명입니다.");
        return false;
    }

    if (currentPassword === newPassword && currentPassword) {
        alert("기존 비밀번호와 신규 비밀번호는 같을 수 없습니다.");
        return false;
    }

    if (checkNewPassword !== newPassword) {
        alert("신규 비밀번호가 서로 다릅니다!");
        return false;
    }

    let query = {
        name,
        birthday,
        companyName,
        email,
        companyPhone,
        phone
    };

    if (currentPassword) {
        query = {
            ...query,
            "currentPassword" : sha512(currentPassword),
            "newPassword" : sha512(newPassword)
        };
    }

    sendQuery("editUser", {}, query);
};

export {checkForm};