import React, { useEffect, useState } from "react";
import style from "./Mypage.module.scss";
import {
  queryLink,
  handleMyPageError,
  sendQuery,
} from "../../../Function/sendQuery";

import ComponentContainer from "../../../global/ComponentContainer/ComponentContainer";

import { createBrowserHistory } from "history";
import { autoHypen } from "../../../Function/autoHypen";

import { checkForm } from "./Function/checkForm";

import MyPageItem from "./items/MyPageItems";

const history = createBrowserHistory({
  forceRefresh: true,
});

const axios = require("axios").default;

const MyPage = () => {
  const [res, setRes] = useState({});

  useEffect(() => {
    axios
      .get(queryLink + "/users", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRes(response.data);
        }
      })
      .catch((error) => {
        handleMyPageError(error);
      });
  }, []);

  const config = {
    title: "MyPage",
    sectionID: "MyPage",
    bgImg: "InquiryMypage",
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setRes({
      ...res,
      [name]: value,
    });
  };

  const handleName = (e) => {
    const { name, value } = e.target;

    setRes({
      ...res,
      [name]: value.replace(/[^a-zA-Z가-힣ㄱ-ㅎ ]/g, "").slice(0, 10),
    });
  };

  const handleBirthDay = (e) => {
    const { name, value } = e.target;

    setRes({
      ...res,
      [name]: value.replace(/[^0-9]/g, "").slice(0, 8),
    });
  };

  const handleNumber = (e) => {
    const { name, value } = e.target;

    setRes({
      ...res,
      [name]: autoHypen(value),
    });
  };

  const handleDeleteAccount = () => {
    const check = window.confirm(
      "정말로 계정을 삭제하시겠습니까? 이 작업은 절대 되돌릴 수 없습니다!"
    );

    if (check) {
      sendQuery("deleteAccount");
    } else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    checkForm(res);
    setRes({
      ...res,
      currentPassword: "",
      newPassword: "",
      checkNewPassword: "",
    });
  };

  if (!localStorage.getItem("token")) {
    alert("로그인이 필요합니다.");
    history.replace("/login");
  } else {
    return (
      <ComponentContainer config={config}>
        <form onSubmit={handleSubmit} className={style.form}>
          <div className={style.inputContainer}>
            <MyPageItem
              name="사용자명"
              value={res.name}
              id="name"
              onValueChange={handleName}
            />
            <MyPageItem
              name="생년월일"
              value={res.birthday}
              id="birthday"
              onValueChange={handleBirthDay}
            />
            <MyPageItem
              name="업체명"
              value={res.companyName}
              id="companyName"
              onValueChange={handleName}
            />
            <MyPageItem
              name="이메일"
              value={res.email}
              id="email"
              onValueChange={handleInput}
              type="email"
              disable={true}
            />
          </div>

          <div className={style.inputContainer}>
            <MyPageItem
              name="회사 번호"
              value={res.companyPhone}
              id="companyPhone"
              onValueChange={handleNumber}
              type="tel"
            />
            <MyPageItem
              name="휴대전화"
              value={res.phone}
              id="phone"
              onValueChange={handleNumber}
              type="tel"
            />
          </div>

          <div className={style.inputContainer}>
            <MyPageItem
              name="기존 비밀번호"
              value={res.currentPassword}
              id="currentPassword"
              onValueChange={handleInput}
              type="password"
            />
            <MyPageItem
              name="신규 비밀번호"
              value={res.newPassword}
              id="newPassword"
              onValueChange={handleInput}
              type="password"
            />
            <MyPageItem
              name="신규 비밀번호 확인"
              value={res.checkNewPassword}
              id="checkNewPassword"
              onValueChange={handleInput}
              type="password"
            />
          </div>

          <div className={style.textContainer}>
            <a href="/#" onClick={handleDeleteAccount}>
              회원 탈퇴하기
            </a>
          </div>

          <div className={style.buttonContainer}>
            <button type="submit">저장하기</button>
            <button type="reset">취소하기</button>
          </div>
        </form>
      </ComponentContainer>
    );
  }
};

export default MyPage;
