import React, {useState} from "react";
import classNames from "classnames/bind";
import styles from "./ProductSearch.module.scss";

import {FiSearch} from "react-icons/all";


import {createBrowserHistory} from 'history';
const history = createBrowserHistory({
    forceRefresh: true
});

const cx = classNames.bind(styles);


const ProductSearch = () => {
    // form value
    const [searchText, setSearchText] = useState({});
    
    const handleSubmit = (e) => {
        e.preventDefault();

        console.log(searchText);

        if (!Object.keys(searchText).length) {
            alert("검색 내용은 공백으로 둘 수 없습니다!");
            return false;
        }

        history.replace(`/product/search/${searchText.keyword}`)
    };

    const handleInput = (e) => {
        const {name, value} = e.target;

        if (/[가-힣ㅏ-ㅣㄱ-ㅎ]/.test(value)) {
            alert("한글 검색은 현재 준비중입니다.");
            return false;
        }

        setSearchText({
            [name] : value.replace(/[^a-zA-Z0-9\- ]/g, "")
        });
    };

    return (
        <form id="ProductSearch" className={cx('container')} onSubmit={handleSubmit}>
            <label>
                <input type="text" className={cx('searchByText')} name="keyword" placeholder="찾으시는 제품명을 입력해주세요." onChange={handleInput} value={searchText.keyword || ""}/>
            </label>

            <button type="submit" className={cx('submitBtn')}><FiSearch /> 검색</button>
        </form>
    );
};

export default ProductSearch