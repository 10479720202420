import React from "react";
import TableRow from "./Row/TableRow";
import {FiChevronRight} from "react-icons/all";

const Table = (props) => {
    const {header, code, child} = props.data;
    const className = props.className || "";

    return (
        <div className={`Table ${className}`} id={code}>
            {className === "resTable" ? <p className="tableName"><FiChevronRight /> {props.data.name}</p> :""}
            <TableRow items={header} className={`row header`} />
            {child.map((items, i) => <TableRow items={items} key={i} className={`row`} />)}
        </div>
    );
};

export default Table;